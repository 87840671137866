var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "roles-container" },
    [
      !_vm.loginInterception
        ? _c("el-alert", {
            attrs: {
              closable: false,
              "show-icon": "",
              title:
                "检测到您当前的登录拦截已关闭，无法模拟切换角色功能，请在src/config/setting.config.js中配置loginInterception为ture，开启登录拦截",
              type: "success",
            },
          })
        : _vm._e(),
      _c("el-alert", {
        attrs: {
          closable: false,
          "show-icon": "",
          title: `当前路由模式为：{ authentication:${_vm.authentication} }，是否开启角色权限控制功能：{ rolesControl:${_vm.rolesControl} }`,
          type: "success",
        },
      }),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-position": "top",
            "label-width": "140px",
            model: _vm.form,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "账号切换" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.handleChangeRole },
                  model: {
                    value: _vm.form.account,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "account", $$v)
                    },
                    expression: "form.account",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "admin" } }, [
                    _vm._v("admin"),
                  ]),
                  _c("el-radio-button", { attrs: { label: "editor" } }, [
                    _vm._v("editor"),
                  ]),
                  _c("el-radio-button", { attrs: { label: "test" } }, [
                    _vm._v("test"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "过期Token模拟访问(令牌失效5s)" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.refreshToken } },
                [_vm._v(" 点击模拟token过期访问接口，无痛刷新 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "描述" } },
            [
              _vm._v(" 当前账号拥有的角色 "),
              _c("el-tag", [_vm._v(_vm._s(JSON.stringify(_vm.role)))]),
              _vm._v(" ，权限点 "),
              _c("el-tag", [_vm._v(_vm._s(JSON.stringify(_vm.ability)))]),
              _vm._v(" ，当前token "),
              _c("el-tag", [_vm._v(_vm._s(_vm.token))]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "按钮级角色" } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: ["admin"],
                      expression: "['admin']",
                    },
                  ],
                  attrs: { type: "primary" },
                },
                [_vm._v(' 拥有["admin"]角色的按钮 ')]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: { role: ["admin"], mode: "except" },
                      expression: "{ role: ['admin'], mode: 'except' }",
                    },
                  ],
                  attrs: { type: "danger" },
                },
                [_vm._v(' 未拥有["admin"]角色的按钮 ')]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: ["editor"],
                      expression: "['editor']",
                    },
                  ],
                  attrs: { type: "primary" },
                },
                [_vm._v(' 拥有["editor"]角色的按钮 ')]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: { role: ["editor"], mode: "except" },
                      expression: "{ role: ['editor'], mode: 'except' }",
                    },
                  ],
                  attrs: { type: "danger" },
                },
                [_vm._v(' 未拥有["editor"]角色的按钮 ')]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: { role: ["admin", "editor"], mode: "allOf" },
                      expression:
                        "{ role: ['admin', 'editor'], mode: 'allOf' }",
                    },
                  ],
                  attrs: { type: "primary" },
                },
                [_vm._v(' 同时拥有["admin","editor"]角色的按钮 ')]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: ["test"],
                      expression: "['test']",
                    },
                  ],
                  attrs: { type: "primary" },
                },
                [_vm._v(' 拥有["test"]角色的按钮 ')]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "按钮级权限点" } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: { ability: ["READ"] },
                      expression: "{ ability: ['READ'] }",
                    },
                  ],
                  attrs: { type: "primary" },
                },
                [_vm._v(' 拥有["READ"]权限点的按钮 ')]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: { ability: ["READ"], mode: "except" },
                      expression: "{ ability: ['READ'], mode: 'except' }",
                    },
                  ],
                  attrs: { type: "danger" },
                },
                [_vm._v(' 未拥有["READ"]权限点的按钮 ')]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: { ability: ["WRITE"] },
                      expression: "{ ability: ['WRITE'] }",
                    },
                  ],
                  attrs: { type: "primary" },
                },
                [_vm._v(' 拥有["WRITE"]权限点的按钮 ')]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: { ability: ["WRITE"], mode: "except" },
                      expression: "{ ability: ['WRITE'], mode: 'except' }",
                    },
                  ],
                  attrs: { type: "danger" },
                },
                [_vm._v(' 未拥有["WRITE"]权限点的按钮 ')]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: { ability: ["DELETE"] },
                      expression: "{ ability: ['DELETE'] }",
                    },
                  ],
                  attrs: { type: "primary" },
                },
                [_vm._v(' 拥有["DELETE"]权限点的按钮 ')]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: { ability: ["DELETE"], mode: "except" },
                      expression: "{ ability: ['DELETE'], mode: 'except' }",
                    },
                  ],
                  attrs: { type: "danger" },
                },
                [_vm._v(' 未拥有["DELETE"]权限点的按钮 ')]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "按钮级角色&权限点" } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: { role: ["admin"], ability: ["DELETE"] },
                      expression: "{ role: ['admin'], ability: ['DELETE'] }",
                    },
                  ],
                  attrs: { type: "primary" },
                },
                [_vm._v(' 拥有["admin"]角色或者["DELETE"]权限点的按钮 ')]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: {
                        role: ["editor"],
                        ability: ["READ"],
                        mode: "allOf",
                      },
                      expression:
                        "{ role: ['editor'], ability: ['READ'], mode: 'allOf' }",
                    },
                  ],
                  attrs: { type: "primary" },
                },
                [_vm._v(' 同时拥有editor和["READ"]权限点的按钮 ')]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permissions",
                      rawName: "v-permissions",
                      value: {
                        role: ["admin"],
                        ability: ["DELETE"],
                        mode: "except",
                      },
                      expression:
                        "{\n          role: ['admin'],\n          ability: ['DELETE'],\n          mode: 'except',\n        }",
                    },
                  ],
                  attrs: { type: "danger" },
                },
                [_vm._v(' 没有admin和["DELETE"]权限点的按钮 ')]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("h3", [_vm._v("intelligence模式(前端控制路由)")]),
      _c("p", [_vm._v("用法请阅读文档")]),
      _c("h3", [_vm._v("all模式(后端动态路由控制)")]),
      _c("p", [_vm._v("用法请阅读文档")]),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    border: "",
                    data: _vm.tableData,
                    "default-expand-all": "",
                    "row-key": "path",
                    "tree-props": {
                      children: "children",
                      hasChildren: "hasChildren",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "name",
                      prop: "name",
                      "show-overflow-tooltip": "",
                      width: "220",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "path",
                      prop: "path",
                      "show-overflow-tooltip": "",
                      width: "220",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "component",
                      prop: "component",
                      "show-overflow-tooltip": "",
                      width: "220",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "redirect",
                      prop: "redirect",
                      "show-overflow-tooltip": "",
                      width: "220",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "title",
                      prop: "meta.title",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "icon",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.meta
                              ? _c(
                                  "span",
                                  [
                                    row.meta.icon
                                      ? _c("vab-icon", {
                                          attrs: { icon: row.meta.icon },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "noClosable",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.meta
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.meta.noClosable || false) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "noKeepAlive",
                      "show-overflow-tooltip": "",
                      width: "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.meta
                              ? _c(
                                  "span",
                                  [
                                    !row.meta.noKeepAlive
                                      ? [_vm._v("false")]
                                      : [_vm._v("true")],
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "badge",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.meta
                              ? _c("span", [
                                  _vm._v(" " + _vm._s(row.meta.badge) + " "),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "isCustomSvg",
                      "show-overflow-tooltip": "",
                      width: "140",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.meta
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.meta.isCustomSvg || false) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "tabHidden",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.meta
                              ? _c(
                                  "span",
                                  [
                                    !row.meta.tabHidden
                                      ? [_vm._v("false")]
                                      : [_vm._v("true")],
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }