var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-col",
    { attrs: { span: 24 } },
    [
      _c(
        "el-card",
        { staticClass: "page-header", attrs: { shadow: "never" } },
        [
          _c("el-avatar", {
            staticClass: "page-header-avatar",
            attrs: { src: _vm.avatar },
          }),
          _c("div", { staticClass: "page-header-tip" }, [
            _c("p", { staticClass: "page-header-tip-title" }, [
              _vm._v(" " + _vm._s(_vm.handleTips()) + " "),
            ]),
            _c("p", {
              staticClass: "page-header-tip-description",
              domProps: { innerHTML: _vm._s(_vm.description) },
            }),
          ]),
          _c(
            "div",
            { staticClass: "page-header-avatar-list" },
            [
              _c("vab-avatar-list", {
                attrs: { "avatar-list": _vm.avatatList },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }