var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu-management-container" },
    [
      _c(
        "el-card",
        { attrs: { shadow: "hover" } },
        [
          _c(
            "vab-query-form",
            [
              _c(
                "vab-query-form-top-panel",
                { attrs: { span: 12 } },
                [
                  _vm.$perms("setting_menu_insert")
                    ? _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-plus", type: "primary" },
                          on: { click: _vm.handleEdit },
                        },
                        [_vm._v(" 添加 ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              attrs: {
                border: "",
                data: _vm.list,
                "default-expand-all": "",
                "row-key": "path",
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function () {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png"),
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("el-table-column", {
                attrs: { label: "id", prop: "id", "show-overflow-tooltip": "" },
              }),
              false
                ? _c("el-table-column", {
                    attrs: {
                      label: "parentId",
                      prop: "parentId",
                      "show-overflow-tooltip": "",
                    },
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  label: "标题",
                  prop: "meta.title",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "类型",
                  prop: "menutype",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          [
                            scope.row.menutype == "1"
                              ? _c("el-tag", [_vm._v("菜单")])
                              : _vm._e(),
                            scope.row.menutype == "2"
                              ? _c("el-tag", { attrs: { type: "warning" } }, [
                                  _vm._v(" 按钮 "),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "name",
                  prop: "name",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "路径",
                  prop: "path",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "是否隐藏", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " + _vm._s(row.meta.hidden ? "是" : "否") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "是否隐藏当前节点",
                  "show-overflow-tooltip": "",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " + _vm._s(row.meta.levelHidden ? "是" : "否") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "vue文件路径",
                  prop: "component",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "重定向", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " + _vm._s(row.redirect ? row.redirect : "无") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "图标", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.meta && row.meta.icon
                          ? _c("vab-icon", { attrs: { icon: row.meta.icon } })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "是否固定", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.meta && row.meta.noClosable ? "是" : "否"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "是否无缓存",
                  "show-overflow-tooltip": "",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.meta && row.meta.noKeepAlive ? "是" : "否"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "badge", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.meta && row.meta.badge
                          ? _c(
                              "el-tag",
                              { attrs: { effect: "dark", type: "danger" } },
                              [_vm._v(" " + _vm._s(row.meta.badge) + " ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "dot", "show-overflow-tooltip": "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(row.meta && row.meta.dot ? "是" : "否") +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "show-overflow-tooltip": "",
                  width: "185",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm.$perms("setting_menu_update")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(row)
                                  },
                                },
                              },
                              [
                                _c("vab-icon", {
                                  attrs: { icon: "edit-2-line" },
                                }),
                                _vm._v(" 编辑 "),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$perms("setting_menu_delete")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(row)
                                  },
                                },
                              },
                              [
                                _c("vab-icon", {
                                  attrs: { icon: "delete-bin-6-line" },
                                }),
                                _vm._v(" 删除 "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("edit", { ref: "edit", on: { "fetch-data": _vm.fetchData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }