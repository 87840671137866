var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    {
      staticClass: "version-information",
      attrs: { shadow: "hover" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c("vab-icon", { attrs: { icon: "information-line" } }),
              _vm._v(" 信息 "),
              _c("el-tag", { staticClass: "card-header-tag" }, [
                _vm._v("部署时间:" + _vm._s(_vm.updateTime)),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("el-scrollbar", [
        _c("table", { staticClass: "table" }, [
          _c("tr", [
            _c("td", [_vm._v("vue")]),
            _c("td", [_vm._v(_vm._s(_vm.dependencies["vue"]))]),
            _c("td", [_vm._v("@vue/cli")]),
            _c("td", [_vm._v(_vm._s(_vm.devDependencies["@vue/cli-service"]))]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("vuex")]),
            _c("td", [_vm._v(_vm._s(_vm.dependencies["vuex"]))]),
            _c("td", [_vm._v("vue-router")]),
            _c("td", [_vm._v(_vm._s(_vm.dependencies["vue-router"]))]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("element-ui")]),
            _c("td", [_vm._v(_vm._s(_vm.dependencies["element-ui"]))]),
            _c("td", [_vm._v("axios")]),
            _c("td", [_vm._v(_vm._s(_vm.dependencies["axios"]))]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("授权渠道")]),
            _c("td", { attrs: { colspan: "3" } }, [
              _c(
                "a",
                {
                  attrs: {
                    href: "http://vue-admin-beautiful.com/authorization",
                    target: "_blank",
                  },
                },
                [
                  _c("el-button", { attrs: { type: "primary" } }, [
                    _vm._v("购买源码 ￥699"),
                  ]),
                ],
                1
              ),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://github.com/chuzhixin/vue-admin-beautiful/",
                    target: "_blank",
                  },
                },
                [
                  _c("el-button", { attrs: { type: "warning" } }, [
                    _vm._v("开源免费版"),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }