var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dynamic-meta-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { lg: 8, md: 12, sm: 24, xl: 8, xs: 24 } },
            [
              _c(
                "el-card",
                {
                  attrs: { shadow: "hover" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_c("span", [_vm._v("动态标题")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleMeta("DynamicMeta", {
                            title: "vab-demo",
                          })
                        },
                      },
                    },
                    [_vm._v(" 标题变更为 vab-demo ")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleMeta("DynamicMeta", {
                            title: "动态Meta",
                          })
                        },
                      },
                    },
                    [_vm._v(" 还原为默认标题 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, md: 12, sm: 24, xl: 8, xs: 24 } },
            [
              _c(
                "el-card",
                {
                  attrs: { shadow: "hover" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_c("span", [_vm._v("动态徽章")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleBadge("DynamicMeta")
                        },
                      },
                    },
                    [_vm._v("徽章+ 1")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetBadge("DynamicMeta", { badge: "0" })
                        },
                      },
                    },
                    [_vm._v(" 徽章清零 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetBadge("DynamicMeta", { badge: false })
                        },
                      },
                    },
                    [_vm._v(" 移除徽章 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, md: 12, sm: 24, xl: 8, xs: 24 } },
            [
              _c(
                "el-card",
                {
                  attrs: { shadow: "hover" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_c("span", [_vm._v("动态图标")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "icon-selector-popper",
                        trigger: "hover",
                        width: 292,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "reference",
                          fn: function () {
                            return [
                              _c(
                                "el-button",
                                [
                                  _c("vab-icon", { attrs: { icon: _vm.icon } }),
                                  _vm._v(" 修改图标 "),
                                  _c("vab-icon", {
                                    attrs: { icon: "arrow-down-s-line" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("vab-icon-selector", {
                        on: { "handle-icon": _vm.handleIcon },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { lg: 8, md: 12, sm: 24, xl: 8, xs: 24 } },
            [
              _c(
                "el-card",
                {
                  attrs: { shadow: "hover" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function () {
                        return [_c("span", [_vm._v("动态高亮菜单")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleActiveName("Role")
                        },
                      },
                    },
                    [_vm._v(" 高亮至角色菜单 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleActiveName("Notice")
                        },
                      },
                    },
                    [_vm._v(" 高亮至通知菜单 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleActiveName("DynamicMeta")
                        },
                      },
                    },
                    [_vm._v(" 还原默认高亮菜单 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }