var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-container" },
    [
      _c("el-page-header", {
        attrs: { content: "【" + _vm.route.query.title + "】详情页面" },
        on: { back: _vm.goBack },
      }),
      _c("el-alert", {
        attrs: {
          closable: false,
          "show-icon": "",
          title:
            "详情页菜单高亮，并缓存详情页，返回上一页再次打开可看到效果，dynamicNewTab可配置详情页只打开一个tab页还是可以根据参数不同无限打开多个，当前展示的是可以打开多个",
          type: "success",
        },
      }),
      _c(
        "el-form",
        { attrs: { inline: true, model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "输入框缓存" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.text,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "text", $$v)
                  },
                  expression: "form.text",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("vab-json-viewer", {
        attrs: { copyable: "", "expand-depth": 5, sort: "", value: _vm.route },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }