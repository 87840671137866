var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "workflow-container" },
    [
      _vm.lf
        ? _c("Control", {
            staticClass: "vab-control",
            attrs: { lf: _vm.lf },
            on: { "cat-data": _vm.$_catData },
          })
        : _vm._e(),
      _c("NodePanel", { attrs: { lf: _vm.lf } }),
      _c("div", { attrs: { id: "left-view" } }),
      _vm.showAddPanel
        ? _c("AddPanel", {
            staticClass: "add-panel",
            style: _vm.addPanelStyle,
            attrs: { lf: _vm.lf, "node-data": _vm.addClickNode },
            on: { addNodeFinish: _vm.hideAddPanel },
          })
        : _vm._e(),
      _c(
        "el-drawer",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            direction: "rtl",
            size: "400px",
            title: "设置节点属性",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c("PropertyDialog", {
                attrs: { lf: _vm.lf, "node-data": _vm.clickNode },
                on: { setPropertiesFinish: _vm.closeDialog },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "数据", visible: _vm.dataVisible, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.dataVisible = $event
            },
          },
        },
        [_c("DataDialog", { attrs: { "graph-data": _vm.graphData } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }