var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vab-icon", {
    staticClass: "fold-unfold",
    attrs: { icon: _vm.collapse ? "menu-unfold-line" : "menu-fold-line" },
    on: { click: _vm.toggleCollapse },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }