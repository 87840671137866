var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    { staticClass: "vab-footer" },
    [
      _vm._v(" Copyright "),
      _c("vab-icon", { attrs: { icon: "copyright-line" } }),
      _vm._v(" " + _vm._s(_vm.title) + " " + _vm._s(_vm.fullYear) + " "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }