var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            inline: true,
            "label-width": "140px",
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "类型", prop: "menutype" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.menuTypeChange },
                  model: {
                    value: _vm.menutype,
                    callback: function ($$v) {
                      _vm.menutype = $$v
                    },
                    expression: "menutype",
                  },
                },
                _vm._l(_vm.mentypedata, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "按钮权限",
                prop: _vm.form.menutype == "2" ? "menu_code" : "empty",
              },
            },
            [
              _c("el-input", {
                attrs: { disabled: !(_vm.form.menutype == "2") },
                model: {
                  value: _vm.form.menu_code,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "menu_code", $$v)
                  },
                  expression: "form.menu_code",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "父级Id", prop: "parentId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.parentId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "parentId", $$v)
                  },
                  expression: "form.parentId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "name",
                prop: _vm.form.menutype == "1" ? "name" : "empty",
              },
            },
            [
              _c("el-input", {
                attrs: { disabled: !(_vm.form.menutype == "1") },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                disabled: !(_vm.form.menutype == "1"),
                label: "路径",
                prop: _vm.form.menutype == "1" ? "path" : "empty",
              },
            },
            [
              _c("el-input", {
                attrs: { disabled: !(_vm.form.menutype == "1") },
                model: {
                  value: _vm.form.path,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "path", $$v)
                  },
                  expression: "form.path",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "vue文件路径",
                prop: _vm.form.menutype == "1" ? "component" : "empty",
              },
            },
            [
              _c("el-input", {
                attrs: { disabled: !(_vm.form.menutype == "1") },
                model: {
                  value: _vm.form.component,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "component", $$v)
                  },
                  expression: "form.component",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "重定向", prop: "redirect" } },
            [
              _c("el-input", {
                attrs: { disabled: !(_vm.form.menutype == "1") },
                model: {
                  value: _vm.form.redirect,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "redirect", $$v)
                  },
                  expression: "form.redirect",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "meta.title" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.meta.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.meta, "title", $$v)
                  },
                  expression: "form.meta.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图标", prop: "meta.icon" } },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    "popper-class": "icon-selector-popper",
                    trigger: "hover",
                    width: "292",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "reference",
                      fn: function () {
                        return [
                          _c("el-input", {
                            attrs: { disabled: !(_vm.form.menutype == "1") },
                            model: {
                              value: _vm.form.meta.icon,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.meta, "icon", $$v)
                              },
                              expression: "form.meta.icon",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("vab-icon-selector", {
                    on: { "handle-icon": _vm.handleIcon },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "badge" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.meta.badge,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.meta, "badge", $$v)
                  },
                  expression: "form.meta.badge",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "dot" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.dot,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "dot", $$v)
                  },
                  expression: "form.dot",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "隐藏" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.hidden,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "hidden", $$v)
                  },
                  expression: "form.hidden",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "始终显示当前节点" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.alwaysShow,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "alwaysShow", $$v)
                  },
                  expression: "form.alwaysShow",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "自定义svg图标" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.meta.isCustomSvg,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.meta, "isCustomSvg", $$v)
                  },
                  expression: "form.meta.isCustomSvg",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "固定" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.meta.noClosable,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.meta, "noClosable", $$v)
                  },
                  expression: "form.meta.noClosable",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "无缓存" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.meta.noKeepAlive,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.meta, "noKeepAlive", $$v)
                  },
                  expression: "form.meta.noKeepAlive",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "不显示当前标签页" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.meta.tabHidden,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.meta, "tabHidden", $$v)
                  },
                  expression: "form.meta.tabHidden",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }